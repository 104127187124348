import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { Grid, Box, Text, Button, Divider } from "theme-ui"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Breadcrumbs from "../components/Breadcrumbs"

const shortcodes = { Grid, Box, Text, Button, Divider }

export default function DefaultPageLayout({ children, pageContext }) {
  const { title } = pageContext.frontmatter
  
  return (
    <Layout>
      <SEO title={title} />

      <Breadcrumbs title={title} />

      <Grid variant="textWrap">
        <Box>
          <MDXProvider components={shortcodes}>{children}</MDXProvider>
        </Box>
      </Grid>
    </Layout>
  )
}